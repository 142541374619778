.dialog {
  max-width: 746px;
}

.AlertModal {
  position: relative;
  border-radius: 15px;

  .closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 88px 80px 60px;

    h3 {
      margin-bottom: 24px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      text-align: center;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      margin-bottom: 24px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 127%;
      text-align: center;
    }

    button {
      padding: 25px 72px;
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      border-radius: 16px;
      background: #545982;
      color: #ffffff;
    }
  }
}
