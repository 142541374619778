.ForgotPasswordPage {
  .signUp {
    margin-bottom: 64px;
    align-self: flex-end;

    a {
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      text-align: right;
      text-decoration: none;
      color: #545982;
    }
  }

  h1 {
    margin-bottom: 56px;
    align-self: flex-start;
    font-weight: 700;
    font-size: 70px;
    line-height: 100%;
    color: rgba(0, 0, 0, 0.8);
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 56px;

    .row {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .forgotPassword {
        margin: -21px 0 0;
        align-self: flex-start;
        line-height: 112%;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .info {
    margin-top: -32px;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;

    .email {
      display: block;
      margin-bottom: 32px;
      color: #2f80ed;
    }

    p {
      margin: 0;

      span {
        color: #2f80ed;
        cursor: pointer;
      }
    }
  }
}
