.Comments {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 49px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 300px;
  background-color: #f9f9f9;

  .container {
    width: 100%;
    max-width: 1020px;

    .header {
      position: relative;
      display: flex;
      width: 100%;
      height: 80px;
      margin-bottom: 24px;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.8);
      background-color: rgba(84, 89, 130, 0.5);
      border-radius: 50px;
      overflow: hidden;
      cursor: pointer;

      .engagements {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
      }

      .responses {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }

      .responses,
      .engagements {
        &.active {
          color: white;
          background-color: #545982;
          border-radius: 50px;
        }
      }
    }

    .questionsList {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 100%;
      padding-bottom: 40px;
    }

    .commentsList {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 50px;

      .scrollButtonContainer {
        position: absolute;
        z-index: 4;
        top: 60px;
        right: -84px;
        display: none;
        flex-direction: column;

        span {
          color: #ff6161;
          font-weight: bold;
        }

        button {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          width: 80px;
          height: 94px;
          margin-bottom: 19px;
          padding-bottom: 10px;
          background-color: #ea788b;
          border-radius: 50%;
          border: 3px solid black;

          svg {
            width: 75%;
          }
        }
      }
    }

    .noQuestions {
      margin: 0;
      margin-top: 40px;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
