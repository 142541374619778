.SplitTemplate {
  min-height: 100vh;
  display: flex;

  .leftSide {
    width: 50%;
    padding: 40px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rightSide {
    width: 50%;
    display: flex;
    justify-content: center;
    position: relative;
    background: right / cover url('../../assets/images/random-person.jpg');

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
    }

    .info {
      margin: 0 60px 0 100px;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 154px;

      h2,
      p {
        color: #ffffff;
      }

      h2 {
        max-width: 488px;
        margin-bottom: 18px;
        font-weight: 700;
        font-size: 70px;
        line-height: 100%;
      }

      p {
        max-width: 594px;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
      }
    }
  }
}
