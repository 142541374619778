.SignUpPage {
  .signIn {
    margin-bottom: 64px;
    align-self: flex-end;

    a {
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      text-align: right;
      text-decoration: none;
      color: #545982;
    }
  }

  h1 {
    margin-bottom: 56px;
    align-self: flex-start;
    font-weight: 700;
    font-size: 70px;
    line-height: 100%;
    color: rgba(0, 0, 0, 0.8);
  }

  form {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 56px;

    .row {
      height: 46px;
      display: flex;
      align-items: center;
      column-gap: 16px;
    }
  }

  .termsPrivacyMessage {
    max-width: 336px;
    margin-bottom: 0;
    align-self: flex-start;
    line-height: 112%;
    color: rgba(0, 0, 0, 0.5);

    a {
      color: #2f80ed;
      text-decoration: none;
    }
  }
}
