.dialog {
  max-width: 1123px;

  &.multipleChoice {
    max-width: 746px;
  }

  &.submitted {
    max-width: 548px;
  }
}

.backdrop {
  opacity: 1 !important;
}

.QuestionModal {
  position: relative;
  padding: 72px 0;
  background-color: #f9f9f9;
  border-radius: 25px;

  &.submitted {
    border-radius: 20px;
  }

  &.multipleChoice {
    padding-bottom: 54px;
    .question {
      padding-left: 74px;
      padding-right: 80px;
    }
  }

  .closeIcon {
    position: absolute;
    top: 36px;
    right: 27px;
    display: block;
    width: 24px;
    height: 24px;
    font-size: 0;
    background-image: url(../../assets/images/view-meeting/close.svg);
    background-size: contain;
    cursor: pointer;
  }

  .submitMessage {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    p {
      width: 380px;
      text-align: center;
      font-size: 20px;
      margin: 0;

      &.title {
        font-weight: bold;
      }
    }
  }

  .question {
    margin-bottom: 24px;
    padding-left: 45px;
    padding-right: 101px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 64px;
    color: rgba(0, 0, 0, 0.8);
  }

  .requiredInfo {
    margin: 20px 0;
    padding-left: 50px;
    color: #898989;
  }

  .form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    padding-left: 45px;
    padding-right: 101px;
    position: relative;

    .userpic {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 129px;
      height: 104px;
      border-radius: 50%;

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 50%;
      }

      .username {
        margin: 0;
        width: 100%;
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: rgba(0, 0, 0, 0.8);
      }
    }

    textarea {
      width: 100%;
      height: 227px;
      padding-top: 32px;
      padding-bottom: 32px;
      padding-left: 42px;
      padding-right: 42px;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      background-color: white;
      border-radius: 20px;
      border: none;
      resize: none;
      box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    .emojiIcon {
      position: absolute;
      top: 16px;
      right: 23px;
      cursor: pointer;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 29px;
    padding-left: 105px;
    list-style: none;

    li {
      label {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 105px;
    padding-right: 100px;

    &.multipleChoice {
      justify-content: flex-start;
      column-gap: 100px;
      padding-left: 95px;
    }

    .submitButton {
      width: 220px;
      height: 48px;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      color: white;
      margin-right: 7px;
      border-radius: 16px;
    }

    .skipButton {
      margin-right: 0;
    }

    button {
      width: 180px;
      height: 47px;
      font-weight: bold;
      border-radius: 8px;

      &.submitButton {
        margin-left: auto;
        background-color: #545982;
        border: none;
        box-shadow: 0px 34px 34px -18px rgba(0, 76, 189, 0.08);
      }

      &.skipButton {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        color: #545982;
        background-color: transparent;
        border: 2px solid #545982;
        border-radius: 16px;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .messageContainer {
      p {
        margin: 0;
        font-family: 'Inter';
        font-style: normal;
        font-size: 20px;
        line-height: 29px;
        color: rgba(0, 0, 0, 0.8);

        &:first-of-type {
          font-weight: 600;
        }
      }
    }
  }
}
