.ChangePasswordPage {
  .resetPassword {
    margin-bottom: 64px;
    align-self: flex-end;

    a {
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      text-align: right;
      text-decoration: none;
      color: #545982;
    }
  }

  h1 {
    margin: 200px 0 56px;
    align-self: flex-start;
    font-weight: 700;
    font-size: 70px;
    line-height: 100%;
    color: rgba(0, 0, 0, 0.8);
  }

  form {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 56px;

    button {
      align-self: flex-end;
    }
  }

  p {
    margin: 0;
    align-self: flex-start;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.8);
  }
}
