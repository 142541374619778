.dialog {
  max-width: 600px;
}

.ShareMeetingSuccessModal {
  position: relative;
  border-radius: 15px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;

    .closeIcon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    p {
      margin: 0;
      text-align: center;
      font-size: 22px;

      &:first-of-type {
        font-weight: bold;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 30px;
    }
  }
}
