.rdw-dropdown-selectedtext {
  display: unset;
  padding: 0;

  .rdw-dropdown-carettoopen,
  .rdw-dropdown-carettoclose {
    display: none;
  }
}

.rdw-option-active {
  box-shadow: none;
}

.rdw-option-wrapper {
  border: 2px solid #000000;
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    box-shadow: none;
  }
}

.rdw-dropdown-optionwrapper {
  overflow: visible;
}

.rdw-editor-toolbar {
  display: flex;
  gap: 27px;
  align-items: center;
  padding: 0;
  position: absolute;
  bottom: 45px;
  border: none;

  .rdw-history-wrapper,
  .rdw-inline-wrapper {
    display: flex;
    gap: 27px;
    padding-top: 5px;
  }
}

// .rdw-dropdown-wrapper.rdw-text-align-dropdown {
//   width: unset;
// }

.rdw-link-wrapper {
  width: 62px;
  height: 62px;
  margin: 0;
  padding: 1px;
  position: relative;
  border: 2px solid #e7e7e7;
  border-radius: 50%;
  overflow: hidden;
}

.rdw-link-modal {
  height: unset;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rdw-link-modal-target-option {
  display: none;
}

.rdw-dropdown-wrapper {
  height: 63px;
  width: 63px;
  border-radius: 8px;
  border: none;

  &:hover {
    box-shadow: none;
  }

  img {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
}

.public-DraftEditor-content {
  border: 1px solid transparent;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  bottom: 0;
}

.public-DraftStyleDefault-ltr {
  margin: 0;
}

.DraftEditor-root {
  height: 100%;
}

.rdw-editor-main {
  padding: 20px;
}

.rdw-center-aligned-block > div {
  text-align: center;
  word-break: break-all;
}

.rdw-left-aligned-block > div {
  word-break: break-all;
}
