.dialog {
  max-width: 746px;
}

.CopyLinkModal {
  position: relative;
  border-radius: 25px;

  .closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .content {
    padding: 80px 80px 60px;
    display: flex;
    flex-direction: column;

    h3 {
      margin: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      margin: 0;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 100%;
      color: rgba(0, 0, 0, 0.8);

      &:last-of-type {
        margin-bottom: 20px;
      }
    }

    .infoContainer {
      display: flex;
      column-gap: 16px;

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 121%;
          color: #545982;
        }

        span {
          font-family: 'Inter';
          font-style: normal;
          font-size: 24px;
          line-height: 121%;
        }
      }

      .copyIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
