.ReplyBox {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-bottom: 49px;

  .emojiButton {
    position: absolute;
    top: 75px;
    right: 40px;
    width: 48px;
    height: 48px;
    width: 48px;
    height: 48px;
    background-color: white;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      right: 1px;
      bottom: 3px;
      width: 16px;
      height: 16px;
      background-image: url(../../assets/images/view-meeting/plus.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .row {
    display: flex;
    align-items: center;
    gap: 43px;
  }

  .submitButton {
    width: 180px;
    height: 60px;
    margin-left: auto;
    margin-right: 16px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    color: white;
    background-color: #545982;
    border-radius: 16px;
    border: none;
  }

  .textarea {
    width: 100%;
    min-height: 137px;
    overflow: hidden;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 42px;
    padding-right: 130px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    background-color: white;
    border-radius: 60px;
    border: none;
    resize: none;
    box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }

    &:focus {
      outline: none;
    }
  }

  .userpic {
    position: absolute;
    left: -16px;
    transform: translateX(-100%);
    top: 5px;
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
