.CreateOrUpdateMeetingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .overlay {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 86px;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    .message {
      position: relative;
      top: -86px;
      padding: 30px;
      font-weight: bold;
      background-color: white;
      border-radius: 10px;
    }
  }

  .navigationContainer {
    width: 100%;
    padding-top: 32px;
    background-color: #f9f9f9;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    padding: 0 80px;
    background-color: #f9f9f9;

    .innerContainer {
      max-width: 1000px;
      margin-bottom: 20px;
      display: flex;
      gap: 84px;
      flex: 1;

      .mediaContainerContainer {
        position: relative;
        flex: 1;

        .autoSaveToggleContainer {
          position: absolute;
          top: -35px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 100%;
          padding-right: 120px;
        }
      }

      .meetingForm {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 280px;

        .meetingTitle {
          margin-bottom: 18px;
          display: flex;
          gap: 10px;
          position: relative;

          h2 {
            width: 100%;
            height: 40px;
            margin: 0;
            padding: 0 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 75%;
            color: rgba(0, 0, 0, 0.8);
            border: none;
            overflow-x: auto;
            white-space: nowrap;
          }

          button {
            position: absolute;
            top: -16px;
            right: 0;
            transform: translateX(-50%);
            border: none;
            background-color: transparent;
          }
        }

        .buttons {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 10px;

          button {
            width: 226px;
            padding: 20px;
            color: #ffffff;
            background-color: #545982;
            border-radius: 16px;
            cursor: pointer;
            border: none;
            font-weight: 500;
            font-size: 20px;
            line-height: 100%;

            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
