.dialog {
  max-width: 746px;
  max-height: 652px;
}

.ConfirmActionModal {
  padding: 40px 106px 48px 48px;
  position: relative;
  border-radius: 15px;
  background-color: #f9f9f9;

  .closeIcon {
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-bottom: 24px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      margin-bottom: 40px;
      font-size: 24px;
      line-height: 100%;
      color: rgba(0, 0, 0, 0.8);
    }

    .buttons {
      display: flex;
      column-gap: 48px;
      align-self: flex-end;
    }
  }
}
