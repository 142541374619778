.SavingSectionModal {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  .content {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 622px;
    height: 240px;
    background-color: white;
    border-radius: 10px;
    .heading {
      font-weight: bold;
      font-size: 24px;
    }

    .message {
      font-size: 22px;
    }
  }
}
