.SharedMeetingPage {
  min-height: 100vh;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);

  .main {
    max-width: 1200px;
    max-height: 780px;
    width: 100%;
    padding: 72px 72px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 15px;

    h1 {
      margin-bottom: 48px;
      text-align: center;

      span:first-of-type {
        margin-bottom: 18px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 64px;
        color: rgba(0, 0, 0, 0.8);
      }

      span:last-of-type {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 156%;
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
      }
    }

    .hint {
      margin-bottom: 16px;
      text-align: center;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 156%;
      color: rgba(0, 0, 0, 0.8);
    }

    form {
      margin-bottom: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 20px;

      label {
        width: 600px;

        input {
          text-align: center;
        }
      }

      .error {
        margin-top: 5px;
        color: red;
      }

      button {
        margin-bottom: 24px;
        padding: 25px 72px;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
      }
    }

    .processingContainer {
      height: 56px;
      margin-bottom: 46px;
      display: flex;
      flex-direction: column;
      align-items: center;
      column-gap: 4px;

      span {
        margin: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 156%;
        text-align: center;
        color: rgba(0, 0, 0, 0.8);
      }
    }

    .expirationInfo {
      margin: 0;

      span:first-of-type {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 156%;
        text-align: center;
        color: rgba(0, 0, 0, 0.8);
      }

      span:last-of-type {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 156%;
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}
