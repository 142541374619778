.MeetingsPage {
  min-height: 100vh;

  .container {
    min-height: calc(100vh - 70px);
    padding: 32px 72px;
    background-color: #f9f9f9;

    .tableContainer {
      padding: 12px 24px 0;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      background-color: #ffffff;

      .table {
        width: 100%;

        thead tr,
        tbody tr:not(:last-of-type) {
          &::after {
            content: '';
            height: 1px;
            display: block;
            position: absolute;
            left: 8px;
            right: 8px;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.05);
          }
        }

        thead {
          height: 46px;

          tr {
            position: relative;

            th {
              padding-right: 32px;
              vertical-align: top;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 112%;
              color: rgba(0, 0, 0, 0.4);

              &:last-child {
                padding-right: 0;
              }
            }
          }
        }

        tbody {
          tr {
            position: relative;

            td {
              padding: 8px 32px 8px 0;
              font-family: 'Inter';
              font-size: 20px;
              line-height: 125%;
              color: rgba(0, 0, 0, 0.8);

              &.capitalize {
                text-transform: capitalize;
              }

              &.title {
                a {
                  text-decoration: none;
                  color: rgba(0, 0, 0, 0.8);

                  &:hover {
                    text-decoration-line: underline;
                    color: #2f80ed;
                  }
                }
              }

              &.buttons {
                padding-right: 20px;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                gap: 24px;

                button {
                  padding: 0;
                  background-color: transparent;
                  border: none;

                  &:disabled {
                    cursor: not-allowed;
                  }

                  &:disabled img {
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        }
      }
    }

    .noMeetings {
      margin: 0;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translateX(50%);
      text-align: center;
      font-weight: 400;
      font-size: 42px;
      line-height: 114%;
      color: rgba(0, 0, 0, 0.5);
    }

    .paginationContainer {
      display: flex;
      justify-content: flex-end;

      .buttonsList {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;

        &.disabled {
          opacity: 0.4;

          a {
            cursor: not-allowed;
          }
        }

        &.active {
          a {
            width: 24px;
            text-align: center;
            font-family: 'Inter';
            line-height: 125%;
            border-radius: 4px;
            background-color: #545982;
            color: #ffffff;
          }
        }

        a {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 125%;
          text-decoration: none;
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}
