.RoundedInput {
  width: 100%;
  position: relative;

  input {
    width: 100%;
    height: 85px;
    padding: 30px 40px;
    border: none;
    border-radius: 60px;
    font-size: 24px;
    line-height: 100%;
    box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);
    color: rgba(0, 0, 0, 0.8);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 60px white inset;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }

    &:disabled {
      text-align: center;
      background-color: #ffffff;
    }

    &:focus {
      outline: none;
    }

    &:focus ~ label,
    &.active ~ label,
    &.error ~ label {
      transform: translateY(-54px);
      font-weight: 500;
      font-size: 14px;
      line-height: 171%;
      color: rgba(0, 0, 0, 0.8);
    }

    &.error ~ label {
      color: #ff9900;
    }

    &.error {
      border-bottom-color: #ff9900;
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    font-size: 24px;
    line-height: 100%;
    color: rgba(0, 0, 0, 0.3);
    pointer-events: none;
    transition: all 0.2s ease;
  }
}
