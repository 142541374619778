.Header {
  width: 100%;
  height: 70px;
  padding: 28px 16px 28px 32px;
  display: flex;
  justify-content: space-between;

  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 64%;
    text-transform: uppercase;
  }

  .userBlock {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .username {
      font-weight: 500;
      font-size: 20px;
      line-height: 89%;
      color: rgba(0, 0, 0, 0.8);
    }

    .userMenuContainer {
      position: relative;

      .userMenu {
        width: 296px;
        margin: 0;
        padding: 32px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        position: absolute;
        z-index: 3;
        top: 58px;
        right: 2px;
        border-radius: 16px;
        list-style: none;
        background-color: #ffffff;

        li {
          button {
            height: 48px;
            padding: 0;
            display: flex;
            flex: 1 1 100%;
            column-gap: 16px;
            justify-content: space-between;
            align-items: center;
            font-size: 24px;
            line-height: 100%;
            border: none;
            background-color: transparent;
            color: rgba(0, 0, 0, 0.8);

            .iconBackground {
              width: 48px;
              height: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              border-radius: 50%;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

              svg {
                position: absolute;
              }
            }
          }
        }
      }
    }

    .userIcon,
    .userPhoto {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
