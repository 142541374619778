.Dropdown {
  width: 100%;
  height: 84px;
  margin-bottom: 22px;
  position: relative;

  .selector {
    padding-left: 71px;
    display: flex;
    align-items: center;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);
    border-radius: 60px;
    font-size: 24px;
    line-height: 100%;
    color: rgba(0, 0, 0, 0.8);
    background-image: url('../../../assets/images/create-question/arrow.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 32px) center;
    cursor: pointer;
  }

  .iconContainer {
    position: absolute;
    left: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
  }

  .iconBackground {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

    img {
      position: absolute;
    }
  }

  .optionsList {
    position: absolute;
    bottom: -8px;
    z-index: 2;
    transform: translateY(100%);
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    right: 0;
    margin: 0;
    row-gap: 40px;
    padding: 44px 32px 44px 0;
    list-style: none;
    background-color: #ffffff;
    box-shadow: 0px 34px 34px -18px rgba(0, 0, 0, 0.08);
    border-radius: 60px;

    li {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 24px;
      line-height: 100%;
      color: rgba(0, 0, 0, 0.4);
      padding-left: 94px;
      cursor: pointer;
      transition: color 0.2s;

      &:hover {
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }
}
