.replyBoxContainer,
.replies {
  margin-left: 70px;
}

.Comment {
  position: relative;
  display: flex;
  margin-bottom: 66px;

  .commentActions {
    position: absolute;
    left: 40px;
    bottom: -38px;
    display: flex;
    align-items: center;
    gap: 8px;

    .saveButton {
      position: absolute;
      top: calc(50% - 24px + 8px);
      right: 0;
      display: flex;
      justify-content: center;
      width: 191px;
      height: 48px;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      color: white;
      background-color: #545982;
      border-radius: 16px;
      border: none;
    }

    .likesContainer {
      display: flex;
      align-items: center;
      gap: 7px;

      .likeIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        background-color: #4791e6;
        border-radius: 50%;

        svg {
          width: 70%;
          transform: scale(-1, 1);
        }
      }
    }

    button {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 0;
      background-color: transparent;
      border: none;
    }

    button,
    .divider {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .userpic {
    position: absolute;
    left: -16px;
    transform: translateX(-100%);
    width: 80px;
    min-width: 80px;
    height: 80px;
    margin-right: 16px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .content {
    width: 100%;
    min-height: 130px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 20px;
    background-color: white;
    border-radius: 60px;
    box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);

    header {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;

      .userInfo {
        margin-bottom: 6px;

        h1 {
          margin-bottom: 8px;
          font-family: 'Outfit';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.8);
        }

        h2 {
          margin: 0;
          font-family: 'Outfit';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.8);
        }
      }

      .headerRight {
        display: flex;
        column-gap: 7px;
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.8);
      }

      .timestamps {
        display: flex;
        align-items: center;
        column-gap: 7px;

        .meetingTime {
          cursor: pointer;
        }
      }

      .menuToggle {
        user-select: none;
        cursor: pointer;
      }

      .commentMenu {
        position: absolute;
        z-index: 1;
        width: 300px;
        height: 176px;
        backdrop-filter: blur(2px);
        background-color: white;
        border-radius: 16px;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05));
        transform: translateY(26px);

        ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 16px;
          height: 100%;
          margin: 0;
          padding: 0;
          background-color: transparent;
          list-style: none;

          li {
            gap: 16px;
            padding: 0;
            padding-left: 32px;
            margin: 0;

            button {
              display: flex;
              align-items: center;
              gap: 16px;
              width: 48px;
              height: 48px;
              font-family: 'Outfit';
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 24px;
              color: rgba(0, 0, 0, 0.8);
              padding: 0;
              background-color: transparent;
              border: none;
              cursor: pointer;

              .iconContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 48px;
                height: 48px;
                background-color: white;
                border-radius: 50%;
                border: none;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
              }
            }
          }
        }
      }
    }

    .textContent {
      position: relative;
      word-break: break-word;

      &.editable {
        div {
          &:focus {
            outline: 0px solid transparent;
          }
        }
      }
    }
  }
}
