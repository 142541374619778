.FilledButton {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 16px;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  background-color: #545982;
  color: #ffffff;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
