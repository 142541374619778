.MeetingVideoPlayer {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 124px);
  padding-top: 20px;
  padding-left: 60px;
  padding-right: 60px;
  background-color: black;

  .backButton {
    position: absolute;
    top: 25px;
    left: 18px;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .sectionsVisibilityButton {
    position: absolute;
    bottom: 25px;
    padding: 0;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: white;
    background-color: transparent;
    border: none;
  }

  video {
    width: 100%;
    height: calc(100vh - 150px - 123px - 20px);
    margin-bottom: 20px;
  }

  .waveFormContainer {
    position: absolute;
    left: 60px;
    right: 60px;
    top: 0;
    bottom: 123px;
    background-color: black;
    z-index: 1;
  }

  .progressBarContainer {
    position: relative;
    z-index: 2;

    .time {
      position: absolute;
      top: 5px;
      right: 0;
      color: white;
    }

    .progressBar {
      width: 100%;
      height: 3px;
      background-color: white;
      cursor: pointer;
      transition: transform 0.2s;

      .progress {
        position: absolute;
        width: 0;
        height: 3px;
        background-color: red;

        &::after {
          content: '';
          position: absolute;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background-color: red;
          right: -10px;
          top: -9px;
          transform: scale(0.2);
          opacity: 0;
          transition: transform 0.2s, opacity 0.2s;
        }
      }

      &.dragging,
      &:hover {
        transform: scaleY(2);
      }

      &.dragging .progress::after,
      &:hover .progress::after {
        opacity: 1;
        transform: scaleY(0.5);
      }
    }
  }

  .controlsContainer {
    display: flex;
    justify-content: center;
    height: 100px;

    .controls {
      display: flex;
      align-items: center;
      gap: 25px;

      div {
        cursor: pointer;
      }

      .playButtonContainer {
        transform: rotate(-90deg);
        height: 22px;
        .playButton {
          position: relative;
          top: 10px;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid white;
          transform: scaleY(1.66);
        }
      }

      .pauseButton {
        display: flex;
        gap: 10px;
        height: 22px;
        div {
          width: 7px;
          height: 100%;
          background-color: white;
        }
      }

      .stopButton {
        width: 22px;
        height: 22px;
        background-color: white;
      }

      .rewindButton {
        position: relative;
        left: 4px;
        transform: rotate(-180deg);
      }

      // .rewindButton {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   width: 38px;
      //   height: 38px;
      //   background-color: white;
      //   border-radius: 50%;

      //   svg {
      //     position: relative;
      //     left: 2px;
      //     transform: scale(0.9);
      //   }
      // }
    }
  }
}
