.Options {
  .info {
    padding-left: 10px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.8);
  }

  .list {
    position: relative;
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 15px;
    margin: 0;
    padding: 0;
    overflow-y: auto;

    /* &::after {
      content: 'Press [Enter] to add more options';
      padding-left: 10px;
      color: #898989;
      position: absolute;
      bottom: -20px;
      transform: translateY(100%);
    } */

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 10px;
      padding-left: 10px;

      .deleteIcon {
        cursor: pointer;
      }

      .optionTitleContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1;
        font-weight: bold;

        img {
          width: 18px;
          height: 18px;
        }

        .optionInput {
          width: 100%;
          padding: 0;
          padding-right: 10px;
          font-size: 24px;
          line-height: 100%;
          color: rgba(0, 0, 0, 0.8);
          border: none;
          background-color: transparent;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
