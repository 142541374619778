.Navigation {
  width: 100%;
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
  column-gap: 32px;

  a {
    width: 214px;
    padding-bottom: 8px;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 64%;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
    text-decoration: none;

    &.active {
      border-bottom: 2px solid #545982;
      opacity: 0.8;
      color: #545982;
    }
  }
}