.NotificationsPage {
  min-height: 100vh;

  .container {
    min-height: calc(100vh - 70px);
    padding: 32px 80px 112px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: #f9f9f9;

    .clearButton {
      margin-bottom: 16px;
      padding: 0;
      align-self: flex-end;
      border: none;
      font-weight: 500;
      font-size: 24px;
      line-height: 75%;
      background-color: transparent;
      color: rgba(0, 0, 0, 0.8);
    }

    .notifications {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    .noNotifications {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 42px;
      line-height: 114%;
      text-align: center;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
