.root {
  position: relative;

  .select {
    width: 100%;
    display: flex;
    align-items: center;
    height: 70px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 24px;
    line-height: 100%;
    color: rgba(0, 0, 0, 0.8);
    background-color: #ffffff;
    box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);
    border-radius: 60px;
    border: none;
    cursor: pointer;

    &.disabled {
      background-color: #ffffff;
      cursor: auto;
    }
  }

  .options {
    position: absolute;
    z-index: 1;
    bottom: -10px;
    transform: translateY(100%);
    width: 100%;
    height: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    overflow-y: auto;

    li {
      padding: 0 30px;
      display: flex;
      align-items: center;
      height: 40px;
      line-height: 100%;
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer;

      &:hover {
        background-color: #f9f9f9;
      }
    }
  }
}
