.SectionMenu {
  position: relative;

  &.emptyList {
    margin-left: 120px;
  }

  .toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .fileInput {
    display: none;
  }

  .menu {
    position: fixed;
    z-index: 2;
    left: 20px;
    bottom: 25px;
    padding: 32px 68px 32px 32px;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 34px 34px -18px rgba(0, 76, 189, 0.08);

    ul {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 0;
      padding: 0;
      list-style: none;

      .subMenu {
        position: absolute;
        left: -372px;
        top: -30px;
        padding-right: 300px;

        &.importSubMenu {
          left: -312px;
        }

        ul {
          padding: 32px 82px 30px 32px;
          background-color: #ffffff;
          border-radius: 16px;
        }
      }

      li {
        position: relative;
        display: flex;
        align-items: center;
        gap: 16px;
        margin: 0;
        padding: 0;
        font-size: 24px;
        line-height: 100%;
        color: rgba(0, 0, 0, 0.4);
        white-space: nowrap;
        user-select: none;
        cursor: pointer;
        transition: color 0.2s;

        .iconContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 48px;
          height: 48px;
          background-color: #ffffff;
          border-radius: 50%;
          border: none;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        }

        &:hover {
          color: rgba(0, 0, 0, 0.8);
        }

        &.disabled {
          opacity: 0.2;
          cursor: auto;
        }

        .nameContainer {
          display: flex;
          align-items: center;
          flex: 1;
          gap: 10px;
          justify-content: space-between;
        }
      }
    }
  }
}
