.Tooltip {
  position: relative;

  &.topLeft {
    transform: none;

    .hiddenTooltip {
      left: unset;
      right: 0;
      transform: translate(70%, -50%);
    }

    .hiddenTooltip,
    .visibleTooltip {
      &::after {
        left: 0;
        transform: translate(-10%, -35%) rotate(45deg);
      }
    }
  }

  .visibleTooltip {
    position: fixed;
    top: var(--top);
    left: var(--left);
  }

  .hiddenTooltip {
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 5px));
  }

  .hiddenTooltip,
  .visibleTooltip {
    position: absolute;
    z-index: 2;
    max-width: 360px;
    padding: 24px 16px;
    border-radius: 16px;
    font-size: 24px;
    line-height: 121%;
    background-color: #545982;
    color: #ffffff;
    white-space: normal;
    word-wrap: break-word;
  }
}
