.dialog {
  max-width: 1120px;
}

.ShareMeetingModal {
  position: relative;
  padding: 36px 60px 24px;
  border-radius: 15px;
  background-color: #f9f9f9;

  .closeIcon {
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;
  }

  header {
    margin-bottom: 15px;

    h1 {
      margin: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .form {
    margin-bottom: 32px;

    .row {
      display: flex;

      &.dates {
        gap: 100px;
      }

      &.inviteRow {
        margin-bottom: 45px;
        gap: 40px;
      }

      .col {
        flex: 1;

        .inputs {
          display: flex;
          gap: 15px;
          margin-bottom: 12px;
        }

        .checkboxContainer {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          gap: 15px;

          .checkboxWrapper {
            flex: 1;
          }

          button {
            flex: 1;
            border: none;
            background-color: transparent;

            &:disabled {
              color: #000000;
              opacity: 0.5;
            }
          }
        }

        .title {
          h2 {
            margin-bottom: 4px;
            display: flex;
            gap: 10px;
            font-size: 24px;
            line-height: 100%;
            color: rgba(0, 0, 0, 0.4);

            .infoIconContainer {
              font-weight: normal;
              font-size: unset;
            }
          }

          hr {
            margin: 0;
            margin-bottom: 12px;
          }
        }
      }

      .error {
        padding-left: 5px;
        color: #bc1823;
      }

      .note {
        padding-left: 5px;
        color: rgb(95, 95, 95);
      }

      .inviteUsersContainer {
        flex-grow: 2;
      }

      .securityOptionsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }

    .messageContainer {
      position: relative;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;

    .info {
      font-size: 21px;
      font-weight: bold;

      &.private {
        color: #bc1823;
      }
    }
  }
}
