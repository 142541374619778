.Notification {
  display: flex;
  align-items: center;
  column-gap: 36px;
  padding: 16px 32px 16px 24px;
  background-color: #ffffff;

  .left {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 246px;
    column-gap: 32px;

    .userpic {
      min-width: 72px;
      max-width: 72px;
      width: 100%;
      height: 72px;
      background-color: #ffffff;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .userInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .userName {
        margin: 0;
        line-break: normal;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        color: rgba(0, 0, 0, 0.8);
      }

      .userTitle {
        margin: 0;
        line-break: normal;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  .textContent {
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;
    color: rgba(0, 0, 0, 0.8);
  }

  .right {
    display: flex;
    flex-basis: 288px;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    column-gap: 16px;

    .meetingPreview {
      width: 150px;
      height: 80px;
      display: flex;
      justify-content: center;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.2);
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../../assets/images/notifications/previewStart.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .createdAt {
      width: 48px;
      text-align: center;
      line-height: 129%;
      color: rgba(0, 0, 0, 0.8);
    }

    button {
      background-color: transparent;
      border: none;
    }
  }
}
